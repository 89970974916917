import {debugLog} from "Core/lib/log";
import {getConfig} from "Core/global/stores/projectConfig";
import liferay from "Core/lib/liferay";
import {projectConfig} from "Core/global/stores/projectConfig";
import {translationsLocale} from "Core/global/stores/translationsLocale";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {Cancel} from "Core/types";
import {isNonEmptyString} from "Core/lib/tests";

// ### ### ###

const logPrefix = "setTranslationsLocale";

export function setTranslationsLocale () :Cancel {
  const cancel = projectConfig.subscribe (() => {
    const env = getConfig ("environment") as undefined|string || "";
    let locale :string;

    if (env.startsWith ("liferay")) {
      const lrLanguageId = liferay.ThemeDisplay.getLanguageId ();

      locale = isNonEmptyString (lrLanguageId) ? lrLanguageId : navigator.language;
    } else {
      locale = navigator.language;
    }

    debugLog (logPrefix, "setting translationsLocale to", locale);
    translationsLocale.set (locale);
  });

  return cancel;
}

