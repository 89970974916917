import {debugLog} from "Core/lib/log";
import {getOldLiferayTranslation} from "Core/global/functions/getOldLiferayTranslation";
//import {isNull} from "Core/lib/tests";
import {isString} from "Core/lib/tests";
import {resolve} from "fluture";
//import {valueOfStore} from "Core/lib/functions/valueOfStore";
//import {writable} from "svelte/store";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {FutureInstance} from "fluture";
import type {TranslationsObject} from "Core/global/translations";
//import type {Writable} from "svelte/store";

// ### ### ###

const logPrefix = "getOldLiferayTranslations";

//export const worker :Writable<null|Worker|"done"> = writable (null);
//export const workerResult :Writable<TranslationsObject> = writable ({} as TranslationsObject); /* eslint-disable-line max-len */
//export const cancelWorker = () :void => {
//  const $worker = valueOfStore (worker);
//
//  if ($worker instanceof Worker) {
//    $worker.terminate ();
//  }
//
//  worker.set (null);
//};

//const initWorker = (tks :Set<string>) :void => {
//  debugLog (logPrefix, "instantiating Worker");
//
//  const $worker = valueOfStore (worker);
//
//  if (isNull ($worker)) {
//    const w = new Worker (new URL ("./getOldLiferayTranslationsWorker.ts", import.meta.url));
//    worker.set (w);

//    w.onmessage = (event :{ data :TranslationsObject }) => {
//      const to = event.data;
//
//      workerResult.set (to);
//      worker.set ("done");
//    };
//
//    w.postMessage (tks);
//  }
//};

export const getOldLiferayTranslations
= (tks :Set<string>) :FutureInstance<Error, TranslationsObject> => {
  debugLog (logPrefix, tks);

  if (globalThis.Worker) {
    // sideeffect!! launches worker thread
    //initWorker (tks); // disabled for now
  }

  const handler :ProxyHandler<TranslationsObject> = {
    get (target, prop, receiver) {
      const rv = isString (prop)
        ? tks.has (prop)
          ? getOldLiferayTranslation (prop)
          : void 0
        : Reflect.get (target, prop, receiver);

      return rv;
    },
  };
  const proxyTO = new Proxy ({}, handler);

  const rv = resolve (proxyTO);

  return rv;
};

