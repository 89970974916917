import {Future} from "fluture";
import {debugLog} from "Core/lib/log";
import {fork} from "fluture";
import {getConfig} from "Core/global/stores/projectConfig";
import {getLiferayTranslations} from "Core/global/functions/getLiferayTranslations";
import {getOldLiferayTranslations} from "Core/global/functions/getOldLiferayTranslations";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {Cancel} from "Core/types";
import type {FutureInstance} from "fluture";
import type {RejectFunction} from "fluture";
import type {ResolveFunction} from "fluture";
import type {TranslationsObject} from "Core/global/translations";

// ### ### ###

const logPrefix = "getLiferayTranslationsObject";

export const getLiferayTranslationsObject
= (tks :Set<string>) :FutureInstance<Error, TranslationsObject> => {
  debugLog (logPrefix, tks);

  const rv = Future ((
      rej :RejectFunction<Error>,
      res :ResolveFunction<TranslationsObject>
  ) => {
    let c :Cancel;
    const environmentString = getConfig ("environment") as undefined|string || "";

    if (environmentString === "liferay") {
      // old liferay (<7.3), translations via blocking calls
      c = fork (rej) (res) (getOldLiferayTranslations (tks));
    } else {
      // new Liferay (>=7.3), translations via one async call
      c = fork (rej) (res) (getLiferayTranslations (tks));
    }

    return c;
  });

  return rv;
};

