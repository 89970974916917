import {writable} from "svelte/store";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {Writable} from "svelte/store";

// ### ### ###

export const projectTranslationKeys :Writable<Set<string>>
= writable (new Set ());

