import {coreTranslationsObject} from "Core/global/stores/coreTranslationsObject";
import {debugLog} from "Core/lib/log";
import {derived} from "svelte/store";
import {projectTranslationsObject} from "Core/global/stores/projectTranslationsObject";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {Fn} from "Core/types";
import type {TranslationsObject} from "Core/global/translations";
import type {Unsubscriber} from "svelte/store";

// ### ### ###

const logPrefix = "initTranslationsDone";

const setFn = (
    [cto, pto] :[undefined|TranslationsObject, undefined|TranslationsObject],
    set :Fn<boolean, void>
) :void|Unsubscriber => {
  //debugLog (logPrefix, "setFn cto", cto);
  //debugLog (logPrefix, "setFn pto", pto);

  const newValue = Boolean (cto && pto);

  debugLog (logPrefix, "setting store to", newValue);
  set (newValue);
};

export const initTranslationsDone
= derived ([coreTranslationsObject, projectTranslationsObject], setFn, false);

