import {debugLog} from "Core/lib/log";

function registerComponent ([name, component] :[string, CustomElementConstructor]) :void {
  if (customElements.get (name) === void 0) {
    debugLog ("registerComponent", "Registering component:", name);

    customElements.define (name, component);
  }
}

export default registerComponent;

