//import {debugLog} from "Core/lib/log";
import {resolve} from "fluture";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {FutureInstance} from "fluture";
import type {TranslationsObject} from "Core/global/translations";

// ### ### ###

//const logPrefix = "getDefaultTranslationsObject";

export const getDefaultTranslationsObject
= (/*tks :Set<string>*/) :FutureInstance<Error, TranslationsObject> => {
  //debugLog (logPrefix, tks);

  return resolve ({} as TranslationsObject); // defaultTranslations non-existent at the moment
};

