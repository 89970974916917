import {prefixKeysWith} from "Core/lib/functions/prefixKeysWith";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {CoreTranslations} from "Core/global/translations";

// ### ### ###

export const translationPrefix = "__listBase_extendedFilter__";

export const translations :CoreTranslations = prefixKeysWith (translationPrefix) ({
  "clear": {
    de: "Verwerfen",
    en: "Clear",
  },
  "submit": {
    de: "Anwenden",
    en: "Submit",
  },
});

