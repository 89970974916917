//import {debugLog} from "Core/lib/log";
import {isUndefined} from "Core/lib/tests";
import memoize from "fast-memoize";
import {valueOfStore} from "Core/lib/functions/valueOfStore";
import {writableOnce} from "Core/lib/writableOnce";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {Fn} from "Core/types";
import type {JsonValue} from "Core/types";

export type ProjectConfig = Record<string, JsonValue>; // TODO improve

// ### ### ###

export const projectConfig = writableOnce<ProjectConfig> ();

let lookupFn :Fn<string, undefined|JsonValue>;
const lookupFnRaw = (pc :ProjectConfig) => memoize (
    (key :string) :undefined|JsonValue => pc [key]
);

export function getConfig (key :string) :undefined|JsonValue {
  //debugLog ("getConfig", key);

  let rv :undefined|JsonValue;

  if (lookupFn) {
    rv = lookupFn (key);
  } else {
    const $pc = valueOfStore (projectConfig);

    if (isUndefined ($pc)) {
      rv = void 0;
    } else {
      lookupFn = lookupFnRaw ($pc);
      rv = lookupFn (key);
    }
  }

  //debugLog ("getConfig", key, "returns", rv);
  return rv;
}

