import {translations as filterTypes} from "./translationsFilterTypes";
import {translations as listBase} from "Core/components/svelte/listBase/translations";
import {translations as listBaseExtendedFilter} from "Core/components/svelte/listBase/extendedFilter/translations";
import {translations as tabs} from "Core/components/svelte/tabs/translations";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {CoreTranslations} from "Core/global/translations";

// ### ### ###

export const coreTranslations :CoreTranslations = Object.freeze ({
  ...filterTypes,
  ...listBase,
  ...listBaseExtendedFilter,
  ...tabs,
});

