import {S} from "Core/lib/sanctuary";
import {coreTranslations} from "Core/global/translations/coreTranslations";
import {debugLog} from "Core/lib/log";
import {defaultLanguageKey} from "Core/global/translations";
import {derived} from "svelte/store";
import {errorLog} from "Core/lib/log";
import {isNonEmptyString} from "Core/lib/tests";
import {pipe} from "Core/lib/functions/pipe";
import {translationsLanguageKey} from "./translationsLanguageKey";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {CoreTranslations} from "Core/global/translations";
import type {Fn} from "Core/types";
import type {LanguageKey} from "Core/global/translations";
import type {Maybe} from "Core/lib/sanctuary";
import type {TranslationsObject} from "Core/global/translations";
import type {Unsubscriber} from "svelte/store";

// ### ### ###

const logPrefix = "coreTranslationsObject";

const coreTranslationsKeys = Object.keys (coreTranslations);
const foundTranslation = S.I; /* eslint-disable-line @typescript-eslint/unbound-method */

const setFn = (
    values :[LanguageKey],
    set :Fn<TranslationsObject, void>
) :void|Unsubscriber => {
  const [lk] = values;

  const newValue = coreTranslationsKeys.reduce (
      (acc :TranslationsObject, key :string) :TranslationsObject => {
        const didNotFindTranslation = () :Maybe<string> => {
          const rv = S.gets (isNonEmptyString) ([key, defaultLanguageKey]) (coreTranslations); /* eslint-disable-line max-len */

          if (S.isNothing (rv)) {
            errorLog (`Found no default translation for key ${key}`);
          }

          return rv as Maybe<string>;
        };

        const expandTranslations = (mTranslation :Maybe<string>) :TranslationsObject => ({
          ...acc,
          [key]: S.fromMaybe<string> ("") (mTranslation),
        });

        const rv = pipe<CoreTranslations, TranslationsObject> ([
          S.gets (isNonEmptyString) ([key, lk]),
          S.ifElse (S.isJust) (foundTranslation) (didNotFindTranslation), /* eslint-disable-line @typescript-eslint/unbound-method */
          S.ifElse (S.isJust) (expandTranslations) (() => acc), /* eslint-disable-line @typescript-eslint/unbound-method */
        ]) (coreTranslations);

        return rv;
      },
      {} as TranslationsObject
  );

  debugLog (logPrefix, `setting core translations to`, newValue);
  set (newValue);
};

export const coreTranslationsObject
= derived ([translationsLanguageKey], setFn);

