import {generateCustomElementFromSvelteComponent as gce} from "Core/lib/customElementGenerators";

const TestPage = gce (
    "TestPage",
    () => import ("Project/testpage/index.svelte"),
);

const TestComponent = gce (
    "TestComponent",
    () => import ("./toplevel/testcomponent/index.svelte"),
);

const HomeHeaderComponent = gce (
    "HomeHeaderComponent",
    () => import ("./toplevel/startpage/index.svelte"),
);

const BigOutNavigationComponent = gce (
    "BigOutNavigationComponent",
    () => import ("./toplevel/bigOutNavigation/index.svelte"),
);

const SPFooter = gce (
    "SPFooter",
    () => import ("./toplevel/startpage/components/00_00_Footer"),
);

const SPFooterNeu = gce (
    "SPFooterNeu",
    () => import ("./toplevel/startpage/components/00_00_FooterNeu"),
);

const SPHomeHeader = gce (
    "SPHomeHeader",
    () => import ("./toplevel/startpage/components/00_01_HomeHeader"),
);

const SPBackgroundText = gce (
    "SPBackgroundText",
    () => import ("./toplevel/startpage/components/00_02_BackgroundText"),
);

const SPSlider = gce (
    "SPSlider",
    () => import ("./toplevel/startpage/components/00_03_Slider"),
);

const SPBildText = gce (
    "SPBildText",
    () => import ("./toplevel/startpage/components/00_04_BildText"),
);

const SPNewsletter = gce (
    "SPNewsletter",
    () => import ("./toplevel/startpage/components/00_05_NewsletterAnmeldung"),
);

const SPCareer = gce (
    "SPCareer",
    () => import ("./toplevel/startpage/components/00_06_BackgroundKarriere"),
);

const SPSliderReferences = gce (
    "SPSliderReferences",
    () => import ("./toplevel/startpage/components/00_07_SliderReferenzen"),
);

const SPContactFormular = gce (
    "SPContactFormular",
    () => import ("./toplevel/startpage/components/00_08_FormularAnfrage"),
);

const SPCookieComponent = gce (
    "SPCookieComponent",
    () => import ("./toplevel/startpage/components/00_09_CookieComponent"),
);

const CXHeader = gce (
    "CXHeader",
    () => import ("./toplevel/01_01_CXHeader"),
);

const CXBusinessSuccess = gce (
    "CXBusinessSuccess",
    () => import ("./toplevel/01_02_CXBusinessSuccess"),
);

const CXImageGroup = gce (
    "CXImageGroup",
    () => import ("./toplevel/01_03_CXImageGroup"),
);

const CXCustomerRelation = gce (
    "CXCustomerRelation",
    () => import ("./toplevel/01_04_CXCustomerRelation"),
);

const CXIconsCRM = gce (
    "CXIconsCRM",
    () => import ("./toplevel/01_05_CXIconsCRM"),
);

const CXDna = gce (
    "CXDna",
    () => import ("./toplevel/01_06_CXDNA"),
);

const CXSliderSolutions = gce (
    "CXSliderSolutions",
    () => import ("./toplevel/01_07_CXSliderSolutions"),
);

const CXOverview = gce (
    "CXOverview",
    () => import ("./toplevel/01_08_CXOverview"),
);

const CXOverviewV2 = gce (
    "CXOverviewV2",
    () => import ("./toplevel/01_08_CXOverview_v2"),
);

const CXTextColumns = gce (
    "CXTextColumns",
    () => import ("./toplevel/01_09_CXTextColumns"),
);

const CXAnalysis = gce (
    "CXAnalysis",
    () => import ("./toplevel/01_10_CXAnalysis"),
);

const CXMenuSilder = gce (
    "CXMenuSlider",
    () => import ("./toplevel/01_11_CXMenuSlider"),
);

const ESTextImage = gce (
    "ESTextImage",
    () => import ("./toplevel/04_03_TextImage"),
);

const ESCategory = gce (
    "ESCategory",
    () => import ("./toplevel/04_01_Category"),
);

const ESNumbers = gce (
    "ESNumbers",
    () => import ("./toplevel/04_02_Numbers"),
);

const ESSlider = gce (
    "ESSlider",
    () => import ("./toplevel/03_02_Slider"),
);

const ESHeader = gce (
    "ESHeader",
    () => import ("./toplevel/03_01_Header"),
);

const CXBlog = gce (
    "CXBlog",
    () => import ("./toplevel/01_13_CXBlog"),
);

const CXSliderEvents = gce (
    "CXSliderEvents",
    () => import ("./toplevel/01_12_CXSliderEvents"),
);

const CX4Icons = gce (
    "CX4Icons",
    () => import ("./toplevel/02_01_Übersicht4Icons"),
);

const BranchesScreenshotList = gce (
    "BranchesScreenshotList",
    () => import ("./toplevel/07_02_ScreenshotList"),
);

const BranchesCheckList = gce (
    "BranchesCheckList",
    () => import ("./toplevel/09_04_CheckList"),
);

const BranchesImageText = gce (
    "BranchesCheckList",
    () => import ("./toplevel/09_04_ImageText"),
);

const BranchesHeader = gce (
    "BranchesHeader",
    () => import ("./toplevel/07_01_BranchesHeader"),
);

const BranchesDetail = gce (
    "BranchesDetail",
    () => import ("./toplevel/08_01_BranchesDetail"),
);

const FlowGraphic = gce (
    "FlowGraphic",
    () => import ("./toplevel/08_02_FlowGrafik"),
);

const HeaderCircle = gce (
    "HeaderCircle",
    () => import ("./toplevel/10_01_HeaderCircle"),
);

const TextImageSpalten = gce (
    "TextImageSpalten",
    () => import ("./toplevel/10_03_TextImageSpalten"),
);

const Overview2x2 = gce (
    "Overview2x2",
    () => import ("./toplevel/09_03_Overview2x2"),
);

const TabMenu = gce (
    "TabMenu",
    () => import ("./toplevel/10_04_TabMenu"),
);

const BranchesText = gce (
    "BranchesText",
    () => import ("./toplevel/07_04_Text"),
);

const BranchesTextSlider = gce (
    "BranchesTextSlider",
    () => import ("./toplevel/07_03_TextSlider"),
);

const BCTextSpaces = gce (
    "BCTextSpaces",
    () => import ("./toplevel/16_06_Text3x3"),
);

const BlogHeader = gce (
    "BlogHeader",
    () => import ("./toplevel/18_01_HeaderBlog"),
);

const ProductScreenshotList = gce (
    "ProductScreenshotList",
    () => import ("./toplevel/16_01_ScreenshotList"),
);

const ProductTabletUndImagebild = gce (
    "ProductHightlights",
    () => import ("./toplevel/16_02_TabletUndImagebild"),
);

const ProductNumbers = gce (
    "ProductNumbers",
    () => import ("./toplevel/16_03_Numbers"),
);

const ProductNumbersColors = gce (
    "ProductNumbersColors",
    () => import ("./toplevel/16_03_Numbers_colors"),
);


const TextBlog = gce (
    "TextBlog",
    () => import ("./toplevel/18_02_TextBlog"),
);

const ESDownloadCTA = gce (
    "ESDownloadCTA",
    () => import ("./toplevel/04_05_DownloadCTA"),
);

const FazitBlog = gce (
    "FazitBlog",
    () => import ("./toplevel/18_04_FazitBlog"),
);

const ProductBCCTA = gce (
    "ProductBCCTA",
    () => import ("./toplevel/16_05_CTA"),
);

const BlogAutor = gce (
    "BlogAutor",
    () => import ("./toplevel/18_06_AutorBlog"),
);

const CTABlog = gce (
    "CTABlog",
    () => import ("./toplevel/18_05_CTABlog"),
);

const FullsizeImage = gce (
    "FullsizeImage",
    () => import ("./toplevel/startpage/components/00_11_FullsizeImage"),
);

const ImageTextVideo = gce (
    "ImageTextVideo",
    () => import ("./toplevel/04_04_ImageTextVideo"),
);

const WebOverviewIcons = gce (
    "WeboverviewIcons",
    () => import ("./toplevel/05_01_OverviewIcon4x2"),
);

const OverviewSquare = gce (
    "OverviewSquare",
    () => import ("./toplevel/05_02_OverviewSquarex3"),
);

const WebinarContent = gce (
    "WebinarContent",
    () => import ("./toplevel/06_01_WebinarContent"),
);

const WebinarContent_v2 = gce (
    "WebinarContent_v2",
    () => import ("./toplevel/06_01_WebinarContent_v2"),
);

const SliderHenri = gce (
    "SliderHenri",
    () => import ("./toplevel/09_05_SliderHenri"),
);

const WebinarDetails = gce (
    "WebinarDetails",
    () => import ("./toplevel/05_03_Components/05_03_WebinarDetails"),
);

const WebinarHeader = gce (
    "WebinarHeader",
    () => import ("./toplevel/05_03_Components/05_03_WebinarHeader"),
);

const WebinarEvent = gce (
    "WebinarEvent",
    () => import ("./toplevel/05_03_Components/05_03_WebinarEvent"),
);

const WebinarAnmeldung = gce (
    "WebinarAnmeldung",
    () => import ("./toplevel/06_02_WebinarAnmeldeformular"),
);

const HenriHeader = gce (
    "HenriHeader",
    () => import ("./toplevel/09_01_HeaderHenri"),
);

const CompanyDownloadFactsheet = gce (
    "CompanyDownloadFactsheet",
    () => import ("./toplevel/10_05_DownloadFactsheet"),
);

const HenriImages = gce (
    "HenriImages",
    () => import ("./toplevel/09_02_ImagesHenri"),
);

const HenriContactformular = gce (
    "HenriContactformular",
    () => import ("./toplevel/09_06_ContactformularStatementHenri"),
);

const LogoTable = gce (
    "LogoTable",
    () => import ("./toplevel/13_01_LogoTable"),
);

const IconOverview3x4 = gce (
    "IconOverview3x4",
    () => import ("./toplevel/15_02_IconOverview3x4"),
);

const KarriereSlider = gce (
    "KarriereSlider",
    () => import ("./toplevel/15_01_KarriereSlider"),
);

const KarriereSliderPrescreen = gce (
    "KarriereSliderPrescreen",
    () => import ("./toplevel/15_01_KarriereSliderPrescreen"),
);

const KarrierePrescreenOverview = gce (
    "KarrierePrescreenOverview",
    () => import ("./toplevel/15_01_KarrierePrescreenOverview"),
);

const TextLinks = gce (
    "TextLinks",
    () => import ("./toplevel/15_03_TextLinks"),
);

const WorkingMethodText = gce (
    "WorkingMethodText",
    () => import ("./toplevel/11_02_Text"),
);

const HeaderCircleV2 = gce (
    "HeaderCircle",
    () => import ("./toplevel/11_01_HeaderCircle"),
);

const CareerHeaderCircle = gce (
    "CareerHeaderCircle",
    () => import ("./toplevel/15_01_HeaderCircle"),
);

const FilterStandorte = gce (
    "FilterStandorte",
    () => import ("./toplevel/15_04_FilterStandorte"),
);

const WorkingMethodText3x2 = gce (
    "WorkingMethodText3x2",
    () => import ("./toplevel/11_03_Text_3x2"),
);

const BlogIcon2x2 = gce (
    "BlogIcon2x2",
    () => import ("./toplevel/18_03_Icon_2x2"),
);

const ProductProcess = gce (
    "ProductProcess",
    () => import ("./toplevel/16_04_Prozess"),
);

const BlogContactformular = gce (
    "BlogContactformular",
    () => import ("./toplevel/18_07_KontaktformularBlog"),
);

const ScrollOnTop = gce (
    "ScrollOnTop",
    () => import ("./toplevel/scrollOnTop"),
);

const BreadcrumbComponent = gce (
    "BreadcrumbComponent",
    () => import ("./toplevel/breadcrumbComponent"),
);

const FilterComponent = gce (
    "FilterComponent",
    () => import ("./toplevel/filterComponent"),
);

const MaintenanceComponent = gce (
    "MaintenanceComponent",
    () => import ("./toplevel/maintenanceComponent"),
);

const FormularComponent = gce (
    "FormularComponent",
    () => import ("./toplevel/formularComponent/formular"),
);

const FormularDirectComponent = gce (
    "FormularComponent",
    () => import ("./toplevel/formularDirect"),
);

const ShareArticle = gce (
    "ShareArticle",
    () => import ("./toplevel/startpage/components/00_20_ShareArticle"),
);

const DigitalAssistant = gce (
    "DigitalAssistant",
    () => import ("./toplevel/DigitalAssistant"),
);

const CrunchHeader = gce (
    "CrunchHeader",
    () => import ("./toplevel/20_01_CrunchHeader"),
);

const CrunchButton = gce (
    "CrunchButton",
    () => import ("./toplevel/20_02_CrunchButton"),
);

const CrunchText = gce (
    "CrunchText",
    () => import ("./toplevel/20_03_CrunchText"),
);

const CrunchImageText = gce (
    "CrunchImageText",
    () => import ("./toplevel/20_04_CrunchImageText"),
);

const CrunchBanner = gce (
    "CrunchBanner",
    () => import ("./toplevel/crunchBanner"),
);

const components = [
  ["crunch-banner", CrunchBanner],
  ["crunch-image-text", CrunchImageText],
  ["crunch-text", CrunchText],
  ["crunch-button", CrunchButton],
  ["crunch-header", CrunchHeader],
  ["digital-assistant", DigitalAssistant],
  ["share-article", ShareArticle],
  ["bc-cta", ProductBCCTA],
  ["bc-text-spaces", BCTextSpaces],
  ["bd-cta-blog", CTABlog],
  ["bd-fazit-blog", FazitBlog],
  ["bd-text-blog", TextBlog],
  ["big-out-navigation", BigOutNavigationComponent],
  ["blog-autor", BlogAutor],
  ["blog-contactformular", BlogContactformular],
  ["blog-header", BlogHeader],
  ["blog-icon-2x2", BlogIcon2x2],
  ["branches-check-list", BranchesCheckList],
  ["branches-detail", BranchesDetail],
  ["branches-flow-graphic", FlowGraphic],
  ["branches-header", BranchesHeader],
  ["branches-image-text", BranchesImageText],
  ["branches-overview-text", Overview2x2],
  ["branches-screenshot-list", BranchesScreenshotList],
  ["branches-text-slider", BranchesTextSlider],
  ["branches-text", BranchesText],
  ["breadcrumb-component",  BreadcrumbComponent],
  ["filter-component",  FilterComponent],
  ["carrer-header-circle", CareerHeaderCircle],
  ["cx-analysis", CXAnalysis],
  ["cx-blog", CXBlog],
  ["cx-business-success", CXBusinessSuccess],
  ["cx-customer-relation", CXCustomerRelation],
  ["cx-dna", CXDna],
  ["cx-header", CXHeader],
  ["cx-icons-crm", CXIconsCRM],
  ["cx-image-group", CXImageGroup],
  ["cx-menu-slider", CXMenuSilder],
  ["cx-overview-icons", CX4Icons],
  ["cx-overview-v2", CXOverviewV2],
  ["cx-overview", CXOverview],
  ["cx-slider-events", CXSliderEvents],
  ["cx-slider-solutions", CXSliderSolutions],
  ["cx-text-columns", CXTextColumns],
  ["download-factsheet", CompanyDownloadFactsheet],
  ["es-category", ESCategory],
  ["es-download-cta", ESDownloadCTA],
  ["es-header", ESHeader],
  ["es-image-text-video", ImageTextVideo],
  ["es-numbers", ESNumbers],
  ["es-slider", ESSlider],
  ["es-text-image", ESTextImage],
  ["fullsize-image", FullsizeImage],
  ["header-circle-v2", HeaderCircleV2],
  ["header-circle", HeaderCircle],
  ["home-header", HomeHeaderComponent],
  ["hr-contactformular", HenriContactformular],
  ["hr-header", HenriHeader],
  ["hr-images", HenriImages],
  ["karriere-filter-standorte", FilterStandorte],
  ["karriere-icon-overview", IconOverview3x4],
  ["karriere-slider-prescreen", KarriereSliderPrescreen],
  ["karriere-prescreen-overview", KarrierePrescreenOverview],
  ["karriere-slider", KarriereSlider],
  ["karriere-text-links", TextLinks],
  ["ni-logo-table", LogoTable],
  ["product-numbers", ProductNumbers],
  ["product-numbers-colors", ProductNumbersColors],
  ["product-process", ProductProcess],
  ["product-screenshot-list", ProductScreenshotList],
  ["product-tablet-imagebild", ProductTabletUndImagebild],
  ["scroll-on-top", ScrollOnTop],
  ["slider-henri", SliderHenri],
  ["sp-background-text", SPBackgroundText],
  ["sp-bild-text", SPBildText],
  ["sp-career", SPCareer],
  ["sp-contact-formular", SPContactFormular],
  ["sp-cookie-component", SPCookieComponent],
  ["sp-footer-neu", SPFooterNeu],
  ["sp-footer", SPFooter],
  ["sp-home-header", SPHomeHeader],
  ["sp-newsletter", SPNewsletter],
  ["sp-slider-references", SPSliderReferences],
  ["sp-slider", SPSlider],
  ["tab-menu-standorte", TabMenu],
  ["test-component", TestComponent],
  ["test-page", TestPage],
  ["text-image-spalten", TextImageSpalten],
  ["web-anmeldung", WebinarAnmeldung],
  ["web-content", WebinarContent],
  ["web-content_v2", WebinarContent_v2],
  ["web-overview-icons", WebOverviewIcons],
  ["web-overview-square", OverviewSquare],
  ["webinar-details", WebinarDetails],
  ["webinar-event", WebinarEvent],
  ["webinar-header", WebinarHeader],
  ["working-method-text-table", WorkingMethodText3x2],
  ["working-method-text", WorkingMethodText],
  ["maintenance-component", MaintenanceComponent],
  ["formular-component", FormularComponent],
  ["formular-direct-component", FormularDirectComponent],
];

export default components;

