import "./index.module.scss";

import {generateCustomElementFromSvelteComponent as gce} from "Core/lib/customElementGenerators";

export type ToplevelComponent = [ string, unknown ]; // TODO type unknown

export const DashboardListGeneral = gce (
    "DashboardListGeneral",
    () => import ("Core/components/toplevel/dashboardListGeneral/index.svelte"),
);

export const DraggableNavbar = gce (
    "DraggableNavbar",
    () => import ("Core/components/toplevel/draggableNavbar/index.svelte"),
);

export const InactivityLiferayHelperComponent = gce (
    "InactivityLiferayHelperComponent",
    () => import ("Core/components/toplevel/inactivityLiferayHelper/index.svelte"),
);

export const ListGeneral = gce (
    "ListGeneral",
    () => import ("Core/components/toplevel/listGeneral/index.svelte"),
);

export const MessageIcon = gce (
    "MessageIcon",
    () => import ("./toplevel/messageIcon/index.svelte"),
);

export const NavigationSearch = gce (
    "NavigationSearch",
    () => import ("Core/components/toplevel/navigationSearch/index.svelte"),
);

export const ToggleCompactMenuButton = gce (
    "ToggleCompactMenuButton",
    () => import ("Core/components/toplevel/toggleCompactMenuButton/index.svelte"),
);

// components listed here will be included in *every* project
const components :ToplevelComponent[] = [
  ["draggable-navbar", DraggableNavbar],
  ["inactivity-liferay-helper", InactivityLiferayHelperComponent],
  //["list-general", ListGeneral],
  ["navigation-search", NavigationSearch],
  ["toggle-compact-menu-button", ToggleCompactMenuButton],
];

export default components;

