import {connectedCallbackFn} from "Core/lib/functions/connectedCallbackFn";
import {debugLog} from "Core/lib/log";
import {errorLog} from "Core/lib/log";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {ClassConstructor} from "Core/types";
import type {Thunk} from "Core/types";

// ### ### ###

interface CE extends HTMLElement {
  connectedCallbackCounter :number;
}

type Module = any; // TODO

export function generateCustomElementFromSvelteComponent (
    name :string,
    getPromiseFn :Thunk<Promise<Module>>,
) :ClassConstructor<CE> {
  return class extends HTMLElement {
    connectedCallbackCounter :number;

    constructor () {
      super();

      //debugLog (name, "constructor");
      //debugLog (name, "constructor", arguments);

      this.connectedCallbackCounter = 0;
    }

    connectedCallback() {
      debugLog ("connectedCallback", `for ${name} - count:`, this.connectedCallbackCounter);
      //debugLog (name, "connectedCallback", this);

      this.connectedCallbackCounter += 1;

      if (this.connectedCallbackCounter === 1) {
        getPromiseFn ()
          .then ((mod)   => connectedCallbackFn (mod.default) (this))
          .catch((error) => errorLog (`An error occurred while loading the component ${name}:`, error))
        ;
      }
    }

    disconnectedCallback () {
      debugLog ("disconnectedCallback", `for ${name}`);
    }
  };
}

