import "./global.scss";
import "./index.module.scss";
import coreComponents from "Core/components";
import {debugLog} from "Core/lib/log";
import {initTranslationsDone} from "Core/global/stores/initTranslationsDone";
import registerComponent from "Core/lib/functions/registerComponent";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {Cancel} from "Core/types";
import type {Readable} from "svelte/store";
import type {ToplevelComponent} from "Core/components";

interface Params {
  projectComponents ?:ToplevelComponent[];
}

// ### ### ###

const preparationsDone :Readable<boolean> = initTranslationsDone;

function registerComponents (projectComponents :ToplevelComponent[]) :void {
  debugLog ("registerComponents");

  const components = [...coreComponents, ...projectComponents];

  components.forEach (registerComponent);
}

function preparedCoreInit (params :Params) :void {
  debugLog ("preparedCoreInit");

  const { projectComponents = [] } = params;

  registerComponents (projectComponents);
}

export function initializeCore (params :Params = {}) :void {
  debugLog ("initializeCore");

  let cancelCoreInit :Cancel;

  cancelCoreInit = preparationsDone.subscribe ((done) => { /* eslint-disable-line prefer-const */
    if (done) {
      preparedCoreInit (params);
      setTimeout (cancelCoreInit);
    }
  });
}

