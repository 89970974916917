// ### Types
/* eslint-disable-next-line sort-imports */
import type {Readable} from "svelte/store";
import type {Writable} from "svelte/store";

// ### ### ###

export function valueOfStore<T> (store :Readable<T>|Writable<T>) :T {
  let rv :T = void 0 as unknown as T;
  const subscriber = (v :T) => { rv = v; };

  store.subscribe (subscriber) (); // immediately unsubscribe

  return rv;
}

