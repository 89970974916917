import {prefixKeysWith} from "Core/lib/functions/prefixKeysWith";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {CoreTranslations} from "Core/global/translations";

// ### ### ###

export const translationPrefix = "__tabs__";

export const translations :CoreTranslations = prefixKeysWith (translationPrefix) ({
  "close": {
    de: "Reiter schließen",
    en: "Close tab",
  },
});

