import {debugLog} from "Core/lib/log";
import {derived} from "svelte/store";
import {fork} from "fluture";
import {getConfig} from "Core/global/stores/projectConfig";
import {getDefaultTranslationsObject} from "Core/global/functions/getDefaultTranslationsObject";
import {getLiferayTranslationsObject} from "Core/global/functions/getLiferayTranslationsObject";
import {projectTranslationKeys} from "./projectTranslationKeys";
import {reject} from "fluture";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {Cancel} from "Core/types";
import type {Fn} from "Core/types";
import type {FutureInstance} from "fluture";
import type {TranslationsObject} from "Core/global/translations";
import type {Unsubscriber} from "svelte/store";

// ### ### ###

const logPrefix = "projectTranslationsObject";

const getProjectTranslations
= (tks :Set<string>) :FutureInstance<Error, TranslationsObject> => {
  const disableLiferayTranslations = getConfig ("disableLiferayTranslations") as undefined|boolean || false;
  const environmentString = getConfig ("environment") as undefined|string || "";
  //debugLog (logPrefix, "environmentString", environmentString);

  let fto :FutureInstance<Error, TranslationsObject>;

  if (environmentString.startsWith ("liferay")) {
    fto = disableLiferayTranslations
      ? getDefaultTranslationsObject (/*tks*/)
      : getLiferayTranslationsObject (tks);
  } else {
    fto = environmentString === "default"
      ? getDefaultTranslationsObject (/*tks*/)
      : reject (new Error (`${logPrefix} getProjectTranslations Unknown environment --${environmentString}--`));
  }

  return fto;
};

let cancel :null|Cancel = null;

const setFn = (
    values :[Set<string>],
    set :Fn<TranslationsObject, void>
) :void|Unsubscriber => {
  const [ptks] = values;

  if (cancel) {
    cancel ();

    cancel = null;
  }

  const environmentString = getConfig ("environment") as undefined|string || "";

  const failure = (error :Error) => {
    // error getting project translations
    debugLog (logPrefix, `Error getting project translations for environment --${environmentString}--`, error);

    cancel = null;

    set ({});
  };

  const success = (newValue :TranslationsObject) => {
    cancel = null;

    debugLog (logPrefix, `setting project translations for environment --${environmentString}-- to`, newValue);
    set (newValue);
  };

  const fpto = getProjectTranslations (ptks);

  cancel = fork (failure) (success) (fpto);
};

export const projectTranslationsObject
= derived ([projectTranslationKeys], setFn);

