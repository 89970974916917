/* global PROJECT */
import "./global.scss";

import {appInit} from "Core/global/appInit";
import components from "./components";
import {debugLog} from "Core/lib/log";
import {translationKeys} from "./translationKeys";

debugLog ("INDEX.JS");

const config = PROJECT;
debugLog ("INDEX.JS", "config", config);

appInit ({
  components,
  config,
  translationKeys,
});

