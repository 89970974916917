import {Future} from "fluture";
import Liferay from "Core/lib/liferay";
import {debugLog} from "Core/lib/log";
import emptyFunction from "Core/lib/functions/emptyFunction";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {FutureInstance} from "fluture";
import type {RejectFunction} from "fluture";
import type {ResolveFunction} from "fluture";
import type {TranslationsObject} from "Core/global/translations";

type GetTranslationsErrorType = string|Error; // TODO does Liferay.Service return a string or an Error in case of exception?

// ### ### ###

const logPrefix = "getLiferayTranslations";

export const getLiferayTranslations
= (tks :Set<string>) :FutureInstance<Error, TranslationsObject> => {
  debugLog (logPrefix, tks);

  const inputKeys = [...tks].join (";"); // TODO what if a key contains ";"?
  const languageId = Liferay.ThemeDisplay.getLanguageId();

  const rv = Future ((
      rej :RejectFunction<Error>,
      res :ResolveFunction<TranslationsObject>
  ) => {
    const rejFn = (e :GetTranslationsErrorType) => {
      rej (e instanceof Error ? e : new Error (e));
    };

    Liferay.Service (
        "/jstranslation.jstranslation/Get-translations",
        { inputKeys, languageId },
        res,
        rejFn,
    );

    return emptyFunction;
  });

  return rv;
};

