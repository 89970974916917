// ### Types ###
import type {JsonValue} from "Core/types";

// ### ### ###

// TODO check if these are correct (enough)
declare const Liferay :{
  Language :{
    get :(a :string) => string;
  };
  Service :(
    service :string|Record<string, unknown>,
    data :Record<string, unknown>|Node|string,
    successCallback :(jsonObject :JsonValue) => void,
    exceptionCallback ?:(exceptionMessage :string) => void,
  ) => unknown;
  ThemeDisplay :{
    getCompanyId :() => unknown; // string?
    getLanguageId :() => unknown; // string?
    getUserId :() => unknown; // string? number?
    getUserName :() => unknown; // string?
    getSiteGroupId :() => unknown; // string?
  };
};

let liferay :typeof Liferay;

try {
  liferay = Liferay;
} catch (e) {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  // just for tsc/eslint - should never be accessed
  liferay = {
    Language: {
      get (v :string) :string { return v; },
    },
    Service (
        a :string|Record<string, unknown>,
        b :Record<string, unknown>|Node|string,
        c :(jsonObject :JsonValue) => void,
        d ?:(exceptionMessage :string) => void,
    ) :unknown { return ""; },
    ThemeDisplay: {
      getCompanyId () :unknown { return null; },
      getLanguageId () :unknown { return null; },
      getSiteGroupId () :unknown { return null; },
      getUserId () :unknown { return null; },
      getUserName () :unknown { return null; },
    },
  };
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

export default liferay;

