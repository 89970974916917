import {default as isEmail} from "validator/es/lib/isEmail";
import {isValidIBAN as isValidIBANLib} from "ibantools";
import {setFunctionName} from "Core/lib/functions/setFunctionName";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {AnyObject} from "Core/types";
import type {AnyTest} from "Core/types";
import type {BooleanTest} from "Core/types";
import type {Fn} from "Core/types";
import type {OptionAtom} from "Core/types";
import type {PK} from "Core/types";
import type {Readable} from "svelte/store";
import type {Test} from "Core/types";
import type {Thunk} from "Core/types";
import type {Writable} from "svelte/store";

type ArrayElementsPassedType<T = never> = T extends never ? any[] : T[];
type IsArrayOfElementsThatPass<T = never> = (v :unknown) => v is ArrayElementsPassedType<T>;

// ### ### ###

// ### Helpers
function testNames (tests :ReadonlyArray<AnyTest>) :string[] {
  return [...tests.reduce ((acc :ReadonlyArray<string>, test) => {
    const n = test.name.length ? test.name : "Unnamed";

    return [ ...acc, n ];
  }, [] as ReadonlyArray<string>)];
}

export function allOf (...tests :ReadonlyArray<AnyTest>) :BooleanTest {
  function f (v :unknown) :boolean {
    return tests.reduce ((acc, test) => acc && test (v), true);
  }

  const fName = `allOf (${testNames (tests).join (" && ")})`;

  setFunctionName (fName, f);

  return f;
}

export function anyOf (...tests :ReadonlyArray<AnyTest>) :BooleanTest {
  function f (v :unknown) :boolean {
    return tests.reduce ((acc, test) => acc || test (v), false);
  }

  const fName = `anyOf (${testNames (tests).join (" || ")})`;

  setFunctionName (fName, f);

  return f;
}

export const isNull = (v :unknown) :v is null => v === null;
export const isNullOr = (...tests :ReadonlyArray<AnyTest>) :BooleanTest => anyOf (isNull, ...tests); /* eslint-disable-line max-len */

export function negate <T extends AnyTest>(t :T) :BooleanTest {
  function f (v :unknown) :boolean {
    return !t (v);
  }

  setFunctionName (`negated ${t.name}`, f);

  return f;
}

export function prefixTestName (prefix :string) {
  return function <T extends AnyTest>(test :T) :T {
    const f = (v :unknown) :ReturnType<T> => test (v) as ReturnType<T>;

    return setFunctionName (`${prefix} ${test.name}`, f as unknown as T);
  };
}
// --- Helpers

// Boolean
export const isBoolean = (v :unknown) :v is boolean => typeof v === "boolean";
export const isFalse = (v :unknown) :v is false => isBoolean (v) && !v;
export const isTrue = (v :unknown) :v is true => isBoolean (v) && v;
export const isNullOrBoolean = isNullOr (isBoolean);

// Number
export const isNumber = (v :unknown) :v is number => typeof v === "number";
export const isNan = (v :unknown) :v is number => isNumber (v) && isNaN (v); // this is intentionally not called isNaN as to avoid confusion with globalThis.isNaN
export const isValidNumber = (v :unknown) :v is number => isNumber (v) && !isNaN (v);
export const isFiniteNumber = (v :unknown) :v is number => isValidNumber (v) && Math.abs (v) !== Infinity; /* eslint-disable-line max-len */
export const isNegativeNumber = (v :unknown) :v is number => isValidNumber (v) && v < 0;
export const isPositiveNumber = (v :unknown) :v is number => isValidNumber (v) && v > 0;
export const isNonNegativeNumber = (v :unknown) :v is number => isValidNumber (v) && v >= 0;
export const isInteger = (v :unknown) :v is number => Number.isInteger (v);
export const isNonNegativeInteger = (v :unknown) :v is number => isNonNegativeNumber (v) && isInteger (v); /* eslint-disable-line max-len */
export const isPositiveInteger = (v :unknown) :v is number => isPositiveNumber (v) && isInteger (v); /* eslint-disable-line max-len */

export const isNaNOrInteger = anyOf (isNan, isInteger);
export const isNaNOrNonNegativeInteger = anyOf (isNan, isNonNegativeInteger);

export const isNullOrNumber = isNullOr (isNumber);
export const isNullOrValidNumber = isNullOr (isValidNumber);
export const isNullOrFiniteNumber = isNullOr (isFiniteNumber);

// String
export const isString = (v :unknown) :v is string => typeof v === "string";
export const isEmptyString = (v :unknown) :v is "" => isString (v) && !v.length;
export const isNonEmptyString = (v :unknown) :boolean => isString (v) && !!v.length;

export const isNullOrString = isNullOr (isString);
export const isNullOrEmptyString = isNullOr (isEmptyString);

// Object
//export const isObject = (v :unknown) :boolean => typeof v === "object" && v !== null;
export const isObject = <T extends AnyObject>
(v :unknown|T) :v is T => typeof v === "object" && v !== null;

// Function
//export const isFunction = (v :unknown) :boolean => typeof v === "function";
export const isFunction = <T = Thunk<any>|Fn<any, any>>(v :unknown) :v is T => typeof v === "function"; /* eslint-disable-line max-len */

// Array
export const isArray = (v :unknown) :v is any[] => Array.isArray (v);
export const isEmptyArray = (v :unknown) :boolean => isArray (v) && !v.length;
export const isNonEmptyArray = (v :unknown) :boolean => isArray (v) && !!v.length;

export function isArrayOfElementsThatPass <T = never>(test :Test<T>) :IsArrayOfElementsThatPass<T> { /* eslint-disable-line max-len */
  function f (v :unknown) :v is ArrayElementsPassedType<T> {
    return isArray (v) && v.every (test);
  }

  const fName = `isArrayOfElementsThatPass (${test.name})`;

  setFunctionName (fName, f);

  return f;
}
export const iaoetp = isArrayOfElementsThatPass;

// Date
export const isDate = (v :unknown) :v is Date => v instanceof Date;

// Other
export const alwaysFalse = (v :unknown) :false => false && v;
export const alwaysTrue = (v :unknown) :true => true || v;
export const anyTrue = (...args :ReadonlyArray<any>) :boolean => args.some ((arg) => arg === true); /* eslint-disable-line max-len */
export const anyTruish = (...args :ReadonlyArray<any>) :boolean => args.some ((arg) => !!arg); /* eslint-disable-line max-len */
export const isEmailAddress = (v :unknown) :v is string => isString (v) && isEmail (v);
export const isHTMLElement = (v :unknown) :v is HTMLElement => v instanceof HTMLElement;
export const isPKType = (v :unknown) :v is PK => anyOf (isInteger, isNonEmptyString) (v);
export const isUndefined = (v :unknown) :v is undefined => typeof v === "undefined";
export const isNotUndefined = (v :unknown) :boolean => !isUndefined (v);
export const isValidIBAN = (v :unknown) :v is string => isString (v) && isValidIBANLib (v); /* eslint-disable-line max-len */
export const trueTest = (v :unknown) :v is boolean|string => v === true || v === "true";

const reDigits = /[0-9]/;
export function isLastCharADigit (v :unknown) :boolean {
  let rv :boolean;

  if (isNonEmptyString (v)) {
    const vs = v as string;
    const lastChar = vs.charAt (vs.length - 1);

    //rv = isInteger (parseInt (lastChar, 10)); // seems expensive
    rv = reDigits.test (lastChar);
  } else {
    rv = false;
  }

  return rv;
}
export const isEmptyStringOrLastCharIsDigit = (v :unknown) :boolean => isEmptyString (v) ? true : isLastCharADigit (v); /* eslint-disable-line max-len */

// Svelte
export const isReadable = (v :unknown) :v is Readable<unknown> => isObject (v) && isFunction (v.subscribe); /* eslint-disable-line max-len */
//export const isWritable = (v :unknown) :v is Writable<unknown> => isReadable (v) && isFunction (v.set) && isFunction (v.update); /* eslint-disable-line max-len */
export function isWritable (v :unknown) :v is Writable<unknown> {
  return isObject (v)
    && isFunction (v.subscribe)
    && isFunction (v.set)
    && isFunction (v.update);
}

// Input Components
export function isEmptyGeneralInputValue (v :unknown) :boolean {
  return anyOf (
      isUndefined,
      isNull,
      isNan,
      isEmptyString,
  ) (v);
}
export const isNotEmptyGeneralInputValue = (v :unknown) :boolean => !isEmptyGeneralInputValue (v); /* eslint-disable-line max-len */

export const isInputOptionLabel = (v :unknown) :v is OptionAtom["label"] => isString (v);
export const isInputOptionValue = (v :unknown) :v is OptionAtom["value"] => isString (v); // TODO this is kind of redundant and not really helpful
export const isInputOption = (v :unknown) :v is OptionAtom => isObject (v)
  ? isInputOptionLabel (v.label) && isInputOptionValue (v.value)
  : false;

// checks for format "yyyy+-mm-dd", the "+" as in more are possible (years can have more than 4 digits, but not fewer)
export const isDateString = (v :unknown) :boolean => {
  let rv :boolean;

  if (isString (v)) {
    const split = v.split ("-");

    if (split.length === 3) {
      const [α, β, γ] = split.map ((v) => v.length);

      rv = α < 4 || β !== 2 || γ !== 2
        ? false
        : split.map ((s) => parseInt (s, 10)).every (isValidNumber);
    } else {
      rv = false;
    }
  } else {
    rv = false;
  }

  return rv;
};

