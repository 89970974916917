import {writable} from "svelte/store";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {Writable} from "svelte/store";

// ### ### ###

export const translationsLocale :Writable<typeof navigator.language>
= writable (navigator.language);

