import {debugLog} from "Core/lib/log";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {ClassConstructor} from "Core/types";
import type {Fn} from "Core/types";

// ### ### ###

export function connectedCallbackFn (
    Component :ClassConstructor
) :Fn<HTMLElement, typeof Component> {
  const localLogPrefix = "connectedCallbackFn";

  return function (element) :typeof Component {
    //debugLog ("connectedCallbackFn was called");
    debugLog (localLogPrefix, "was called on element", element);
    debugLog (localLogPrefix, "was called on element.innerHTML", `--${element.innerHTML}--`);
    //debugLog (localLogPrefix, "was called on element attributes", [...element.attributes]);

    const props = {};

    if (element.hasAttributes ()) {
      [...element.attributes].forEach((attr) => {
        //debugLog (localLogPrefix, "element", element, "attr", attr);

        props[attr.name] = attr.value;
      });
    }

    debugLog (localLogPrefix, "creating instance with props", props);

    const app = new Component ({
      props,
      target: element,
    });

    //debugLog (localLogPrefix, "returns", app);
    return app as typeof Component;
  };
}

export default connectedCallbackFn;

