import {debugLog} from "Core/lib/log";
import {initializeCore} from "Core";
import {projectConfig} from "./stores/projectConfig";
import {projectTranslationKeys} from "Core/global/stores/projectTranslationKeys";
import {setTranslationsLocale} from "Core/global/functions/setTranslationsLocale";
//import {translationsLocale} from "Core/global/stores/translationsLocale";
//import {valueOfStore} from "Core/lib/functions/valueOfStore";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {ProjectConfig} from "./stores/projectConfig";
import type {ToplevelComponent} from "Core/components";

interface AppInitParams {
  components ?:ToplevelComponent[];
  config     ?:ProjectConfig;
  translationKeys ?:Set<string>;
}

// ### ### ###

const logPrefix = "appInit";

export function appInit (params :AppInitParams) :void {
  debugLog (logPrefix, params);

  const {
    components = [],
    config = {},
    translationKeys = new Set(),
  } = params;

  projectConfig.set (Object.freeze ({ ...config })); // first things first
  //debugLog (logPrefix, "after setting projectConfig", JSON.stringify (valueOfStore (projectConfig), void 0, 2));

  // set up translations
  projectTranslationKeys.set (translationKeys);
  //debugLog (logPrefix, "after setting projectTranslationKeys", JSON.stringify (valueOfStore (projectTranslationKeys), void 0, 2));
  setTranslationsLocale ();
  //debugLog (logPrefix, "after setting setTranslationsLocale", JSON.stringify (valueOfStore (translationsLocale), void 0, 2));

  initializeCore ({ projectComponents: components });

  //setTimeout (() => {
    //debugLog (logPrefix, "test projectConfig re-set");

    //projectConfig.set (Object.freeze ({ ...config }));
  //}, 1000);
}


