import {debugLog} from "Core/lib/log";
import {defaultLanguageKey} from "Core/global/translations";
import {derived} from "svelte/store";
import {isLanguageKey} from "Core/global/translations";
import {translationsLocale} from "./translationsLocale";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {Fn} from "Core/types";
import type {LanguageKey} from "Core/global/translations";
import type {Unsubscriber} from "svelte/store";

// ### ### ###

const logPrefix = "translationsLanguageKey";

const setFn = (
    locale :typeof navigator.language,
    set :Fn<LanguageKey, void>
) :void|Unsubscriber => {
  const lk = locale.substr (0, 2);
  const newValue = isLanguageKey (lk) ? lk : defaultLanguageKey;

  debugLog (logPrefix, "setting store to", newValue);
  set (newValue);
};

export const translationsLanguageKey
= derived (translationsLocale, setFn, defaultLanguageKey);

