//import {debugLog} from "Core/lib/log";
//import {devMode} from "Core/lib/devMode";
import {env as flutureEnv} from "fluture-sanctuary-types";
//import {env as flutureEnv} from "../types/fluture-sanctuary-types";
//import {env as flutureEnv} from "../unneeded/types/fluture-sanctuary-types";
import sanctuary from "sanctuary";
/* eslint-disable-next-line sort-imports */
import $ from "sanctuary-def";
//import {timetaken} from "Core/lib/log";

// ### Types
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export interface Maybe<A> {
  "@@type" :"sanctuary/Maybe";
}

export type Nothing = Maybe<any>;

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export interface Either<A, B> {
  "@@type" :"sanctuary/Either";
}

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export interface Pair<A, B> {
  "@@type" :"sanctuary/Pair";
}

// ### ### ###

//    EventType :: Type
const EventType = $.NullaryType (
    "EventType"
) (
    "http://navax.com/sanctuary#EventType"
) ([]) ((x :unknown) => x instanceof Event);

//    HTMLElementType :: Type
const HTMLElementType = $.NullaryType (
    "HTMLElementType"
) (
    "http://navax.com/sanctuary#HTMLElementType"
) ([]) ((x :unknown) => x instanceof HTMLElement);

//    ResponseType :: Type
const ResponseType = $.NullaryType (
    "ResponseType"
) (
    "http://navax.com/sanctuary#ResponseType"
) ([]) ((x :unknown) => x instanceof Response);

const sanctuaryOptions = {
  //checkTypes: true,
  checkTypes: false,
  //checkTypes: devMode,
  //env: sanctuary.env,
  //env: sanctuary.env.concat(flutureEnv),
  env: $.env.concat([
    ...flutureEnv,
    EventType,
    HTMLElementType,
    ResponseType,
  ]),
};

export const S = sanctuary.create (sanctuaryOptions);
export const def = $.create (sanctuaryOptions);

export default S;

/*
// wraps a value into a Maybe
// ! only use for supported values !
export function toMaybe (value) {
  const debugTimer = new Date();

  debugLog ("toMaybe", value);

  const testFunctions = [
    S.is ($.Undefined),
    S.is ($.Null),
    Number.isNaN,
    //S.isNothing,
    //S.isLeft,
    () => {
      //debugLog ("toMaybe last testFn");
      return false;
    },
  ];

  // if any of the testFunctions returns true, test failed
  const failedTest = S.reduce (
      (prevReturn) => (testFn) => prevReturn === false ? testFn (value) : true
  ) (false) (testFunctions);

  function justReturn () {
    let rv = value;

    const isMaybeType = S.is ($.Maybe ($.Any));
    //const isEitherType = S.is ($.Either ($.Any) ($.Any));

    if (isMaybeType (value)) {
      rv = value;
    //} else if (isEitherType (value)) {
      //rv = S.eitherToMaybe (value);
    } else {
      rv = S.Just (value);
    }

    return rv;
  }

  const rv = failedTest
    ? S.Nothing
    : justReturn ();

  //debugLog ("toMaybe", "returns", rv);

  timetaken ({
    message: `toMaybe`,
    //threshold: 10,
    time: new Date() - debugTimer,
  });

  return rv;
}
*/

