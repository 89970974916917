import {isNonEmptyString} from "Core/lib/tests";

// ### Types
/* eslint-disable-next-line sort-imports */

export type LanguageKey
= "de"
| "en";
// if you make a change here you also need to set the value below
export type DefaultLanguageKey = "en";

export type CoreTranslations
= Record<string, Partial<Record<LanguageKey, string>> & Record<DefaultLanguageKey, string>>;

export type TranslationsObject = Record<string, string>;

// ### ### ###

export const languageKeys :LanguageKey[] = [
  "de",
  "en",
];

// if you make a change here you also need to set the type above
export const defaultLanguageKey :LanguageKey = "en";

export const isLanguageKey = (v :unknown) :v is LanguageKey => {
  return isNonEmptyString (v) && languageKeys.includes (v as LanguageKey);
};

