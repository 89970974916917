/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {devMode} from "Core/lib/devMode";

let flagDebug = devMode;
let flagTesting = devMode;

export function setFlag (name :"debug"|"testing", value = false) :void {
  switch (name) {
    case "debug":
      flagDebug = value;
      break;
    case "testing":
      flagTesting = value;
      break;
    default:
      break;
  }
}

export function assert (...args :Parameters<typeof console.assert>) :void {
  if (flagDebug) {
    console.assert (...args); /* eslint-disable-line no-console */
  }
}

export function debug (...args :any[]) :void {
  if (flagDebug) {
    console.debug (...args); /* eslint-disable-line no-console */
  }
}
export { debug as debugLog };

export const debugFilter = (message = "") => <T>(value :T) :T => {
  debug (message, value);

  return value;
};

export const mappedDebugFilter = (message = "") => <T>(value :T) :T => {
  value ["fantasy-land/map"] (debugFilter (message));

  return value;
};

export function dir (...args :any[]) :void {
  console.dir (...args); /* eslint-disable-line no-console */
}

export function error (...args :any[]) :void {
  if (flagDebug || flagTesting) {
    console.error(...args); /* eslint-disable-line no-console */
  }
}
export { error as errorLog };

export function iteratorLog (message = "") {
  return (value :Iterable<any>) :void => {
    for (const item of value) {
      debug (message, item);
    }
  };
}

export function log (...args :any[]) :void {
  console.log (...args); /* eslint-disable-line no-console */
}

export function logAsync (...args :any[]) :ReturnType<typeof setTimeout> {
  return globalThis.setTimeout (debug, 0, ...args);
}

export function trace (...args :any[]) :void {
  if (flagDebug) {
    console.trace (...args); /* eslint-disable-line no-console */
  }
}

export function test (...args :any[]) :void {
  if (flagDebug|| flagTesting) {
    console.log (...args); /* eslint-disable-line no-console */
  }
}

export function timetaken ({
  message   = "",
  threshold = -1,
  time      = -1,
} = {}) :void {
  if (time > threshold) {
    globalThis.setTimeout (() => test (message, "- time taken", time));
  }
}

//export function timetakenFilter ({
  //message   = "",
  //threshold = -1,
  //time      = -1,
//} = {}) {
  //return <T>(value :T) :T => {
    //const timeValue = time > 0 ? Date.now() - time : 0;

    //this.timetaken ({
      //message,
      //threshold,
      //time: timeValue,
    //});

    //return value;
  //};
//}

export function warn (...args :any[]) :void {
  if (flagDebug || flagTesting) {
    console.warn(...args); /* eslint-disable-line no-console */
  }
}

