//import {debugLog} from "Core/lib/log";
import {isUndefined} from "Core/lib/tests";
import {valueOfStore} from "Core/lib/functions/valueOfStore";
import {warn} from "Core/lib/log";
import {writable} from "svelte/store";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {JsonValue} from "Core/types";
import type {Writable} from "svelte/store";

export type ProjectConfig = Record<string, JsonValue>; // TODO improve

export interface WritableOnce<T =any> extends Writable<undefined|T> {
  origSet :Writable<undefined|T>["set"];
}

// ### ### ###

function setOnce (value :any) :void {
  //debugLog ("setOnce", "this", this);
  //debugLog ("setOnce", "value", value);

  const currentValue = valueOfStore (this);

  if (isUndefined (currentValue)) {
    //debugLog ("setOnce", "setting store to", value);

    this.origSet (value);
  } else {
    warn ("setOnce", "cannot set store to", value);
    warn ("setOnce", "store already set to", currentValue);
  }
}

export const writableOnce = <T = any>(initValue ?:T) :WritableOnce<T> => {
  const w = isUndefined (initValue) ? writable () : writable (initValue);
  const aw = Object.create (w);

  aw.origSet = w.set;
  aw.set = setOnce;

  return aw as WritableOnce<T>;
};

