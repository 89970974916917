
export const prefixKeysWith = (prefix :string) => (o :Record<string, any>) :typeof o => {
  const newKeysO = Object.keys (o).reduce ((acc, key) => ({
    ...acc,
    [`${prefix}${key}`]: o [key],
  }), {});

  return Object.assign (Object.create (Object.getPrototypeOf (o)), newKeysO);
};

