import Liferay from "Core/lib/liferay";
import memoize from "fast-memoize";

// ### Types
/* eslint-disable-next-line sort-imports */

// ### ### ###

// use this only for liferay version <7.3
export const getOldLiferayTranslation = memoize (function (txt :string) :string {
  return Liferay.Language.get (txt);
});

