import {prefixKeysWith} from "Core/lib/functions/prefixKeysWith";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {CoreTranslations} from "Core/global/translations";

// ### ### ###

export const translationPrefix = "__filterType__";

export const translations :CoreTranslations = prefixKeysWith (translationPrefix) ({
  "boolean": {
    en: "boolean",
  },
  "contains": {
    de: "enthält",
    en: "contains",
  },
  "equals": {
    de: "=",
    en: "=",
  },
  "not-equals": {
    de: "≠",
    en: "≠",
  },
  "regex": {
  de: "RegEx",
    en: "RegEx",
  },
});

