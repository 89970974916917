import {prefixKeysWith} from "Core/lib/functions/prefixKeysWith";

// ### Types
/* eslint-disable-next-line sort-imports */
import type {CoreTranslations} from "Core/global/translations";

// ### ### ###

export const translationPrefix = "__listBase__";

export const translations :CoreTranslations = prefixKeysWith (translationPrefix) ({
  "dismiss": {
    de: "Verwerfen",
    en: "Dismiss",
  },
  "save": {
    de: "Speichern",
    en: "Save",
  },
});

